export async function get_thorn_applications(functionArea = [], geographicalArea = []) {
    let formdata = new FormData();
    formdata.append('functionArea', functionArea);
    formdata.append('geographicalArea', geographicalArea);

    const response = await fetch('/wp-json/thorn/v1/jobs/' + locale, {
        method: 'POST',
        body: formdata,
    });

    let data = await response.json();

    data = data.map(element => {
        let elm = createElementFromHTML(element[1]);

        const title = elm.querySelector('.header > .template')?.textContent?.trim();
        const company_name = elm.querySelector('.company-name')?.textContent?.trim();
        const remaining_time = elm.querySelector('i.far.fa-clock')?.parentElement?.textContent?.trim();
        const content = elm.querySelector('.teaser > .template')?.children;
        const link = 'https://thorn.peopletrust.dk/candidate/' + elm.querySelector('button')?.getAttribute('href');

        return { title, company_name, remaining_time, content, link };
    });

    function createElementFromHTML(htmlString) {
        const element = document.createElement('div');
        element.innerHTML = htmlString.trim();
    
        // Change this to element.childNodes to support multiple top-level nodes.
        return element.firstChild;
    }

    return data;
}

export async function get_thorn_selectors() {
    const response = await fetch('/wp-json/thorn/v1/selectors/' + locale);
    let data = await response.json();

    let functionArea = "";
    let geographicalArea = "";

    let functionArea_start = data.indexOf('functionArea');
    functionArea_start = data.indexOf('{', functionArea_start + 1);
    let geographicalArea_start = data.indexOf('geographicalArea');
    geographicalArea_start = data.indexOf('{', geographicalArea_start + 1);

    functionArea = data.substring(functionArea_start, data.length);
    geographicalArea = data.substring(geographicalArea_start, data.length);

    let functionArea_end = functionArea.indexOf('}');
    let geographicalArea_end = geographicalArea.indexOf('}');

    functionArea = JSON.parse(functionArea.substring(0, functionArea_end + 1) +'}');
    geographicalArea = JSON.parse(geographicalArea.substring(0, geographicalArea_end + 1) + '}');

    return { functionArea, geographicalArea };
}