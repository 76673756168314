import { get_thorn_applications, get_thorn_selectors } from "./get";

class Thorn {
    constructor() {
        this.functionArea = { dom: null, data: [], selected: {} };
        this.geographicalArea = { dom: null, data: [], selected: {} };

        this.__constructor();
    }

    async __constructor() {
        if ( ! document.querySelector('.open_positions') ) { return; }

        const { functionArea, geographicalArea } = await get_thorn_selectors();

        /* Get data to selectors */
        this.functionArea.data = functionArea.data.body;
        this.geographicalArea.data = geographicalArea.data.body;

        /* Get DOM Selectors */
        this.functionArea.dom = document.getElementById(functionArea.id);
        this.geographicalArea.dom = document.getElementById(geographicalArea.id);

        this.insert_options.bind(this.functionArea)();
        this.selector_events.bind(this.functionArea)();
        this.insert_options.bind(this.geographicalArea)();
        this.selector_events.bind(this.geographicalArea)();

        this.fetch_data();
    }

    insert_options() {
        let amount = 0;
        const selector_amount = this.dom.querySelector('.-selector-amount');

        let options = [];
        this.data.forEach((option, index) => {
            let option_element = document.createElement('li');
            option_element.innerText = option[0];
            option_element.value = option[1];

            option_element.addEventListener('click', () => {
                option_element.classList.toggle('-selected');

                if ( option_element.classList.contains('-selected') ) {
                    this.selected[index] = option_element.value;
                } else { delete this.selected[index]; }

                amount += option_element.classList.contains('-selected') ? 1 : -1;

                selector_amount.innerText = amount;
                selector_amount.classList.toggle('-visible', amount > 0 ? true : false);
            }, true);

            options.push(option_element);
        });

        this.dom.querySelector('.selector-list').append(...options);
    }

    selector_events() {
        const selector_list = this.dom.querySelector('.selector-list');
        const select = this.dom.querySelector('.-selector-top');

        select.addEventListener('click', () => {
            selector_list.setAttribute(
                'aria-expanded', 
                selector_list.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'
            );
        }, false);
    }

    fetch_data() {
        const submit = document.getElementById('look_up_jobs');
        submit.addEventListener('click', onsubmit.bind(this), false);
        onsubmit.bind(this)();

        async function onsubmit() {
            const data = await get_thorn_applications(
                Object.values(this.functionArea.selected), 
                Object.values(this.geographicalArea.selected)
            );

            const list = document.getElementById('positions');

            if ( data.length <= 0 ) {
                list.innerHTML = '';
            } else {
                list.innerHTML = '';
                let elements = [];

                data.forEach(element => {
                    let item = `<li class="job-item">
                        <section class="job-item-wrapper">
                        ${ element.company_name || element.remaining_time ? '<div class="job-item-banner">' : ''}
                            ${
                                element.remaining_time 
                                ? '<div>' + element.remaining_time +'</div>'
                                : ''
                            }

                            ${
                                element.company_name 
                                ? '<span>' + element.company_name +'</span>'
                                : ''
                            }
                        ${ element.company_name || element.remaining_time ? '</div>' : ''}

                        <h3 class="job-item-title">${ element.title }</h3>

                        <p class="job-item-content"></p>
                        </section>

                        <section class="job-item-link-container">
                        <a class="job-item-link" href="${ element.link }" target="_blank">${ read_more }</a>
                        </section>
                    </li>`;

                    item = createElementFromHTML(item);

                    let item_contents = item.querySelector('.job-item-content');
                    Array.from(element.content).forEach(elm => item_contents.innerHTML += elm.textContent);

                    elements.push(item);
                });

                list.append(...elements);

                function createElementFromHTML(htmlString) {
                    const element = document.createElement('div');
                    element.innerHTML = htmlString.trim();
                
                    // Change this to element.childNodes to support multiple top-level nodes.
                    return element.firstChild;
                }
            }
        }
    }

}

new Thorn();